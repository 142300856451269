import { FC } from 'react';
import { errorsContent } from '~/src/layouts/error-layout/error-layout.constants';
import { ErrorLayoutProps } from '~/src/layouts/error-layout/error-layout.types';
import * as Styled from './error-layout.styles';

/**
 * Used in Module Federation
 */

export const ErrorLayout: FC<ErrorLayoutProps> = ({ code }) => {
    const content = errorsContent[code];

    return (
        <Styled.ErrorLayout>
            <Styled.ErrorContent>
                <Styled.ErrorCode>{code}</Styled.ErrorCode>
                <Styled.ErrorTitle>{content?.title}</Styled.ErrorTitle>
                <Styled.ErrorText>{content?.text}</Styled.ErrorText>
            </Styled.ErrorContent>
        </Styled.ErrorLayout>
    );
};
