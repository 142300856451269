import { ErrorContentType } from '~/src/layouts/error-layout/error-layout.types';

import { StyledLink } from './error-layout.styles';

export const errorsContent: ErrorContentType = {
    404: {
        title: 'На сайте нет такой страницы',
        text: (
            <>
                Страница была удалена или переименована.<br />
                Перейти <StyledLink href="/">на главную страницу</StyledLink>
            </>
        ),
    },
};
