import styled, { css } from 'styled-components';
import NextLink from 'next/link';
import { FONTS } from '~/src/styles/style-helpers/font-helper';
import { COLORS } from '~/src/styles/tokens/colors';
import { rem } from '~/src/helpers/rem-converter.helper';
import { mediaQuery } from '~/src/styles/mixins';
import { MAX_767 } from '~/src/styles/tokens/break-points';

export const ErrorLayout = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: ${rem(169)};
    margin-bottom: ${rem(197)};

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            padding: 0 ${rem(20)};
        `,
    )};
`;

export const ErrorContent = styled.div`
    max-width: ${rem(532)};
    width: 100%;
`;

export const ErrorCode = styled.h1`
    ${FONTS.sans.ultraWide.size144};
    color: ${COLORS.backgroundLightStroke};
    text-align: center;

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            ${FONTS.sans.ultraWide.size84};
        `,
    )};
`;

export const ErrorTitle = styled.h2`
    ${FONTS.sans.black.size24};
    margin-top: ${rem(24)};
    text-align: center;

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            ${FONTS.sans.black.size20};
        `,
    )};
`;

export const ErrorText = styled.p`
    ${FONTS.compact.regular.size17};
    margin-top: ${rem(20)};
    text-align: center;

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            margin-top: ${rem(16)};
        `,
    )};
`;

export const StyledLink = styled(NextLink)`
    color: ${COLORS.textLightPrimaryLink};
    text-decoration: none;
`;
